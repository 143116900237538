<template>
  <section class="page-wrap banner-wrap">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
          <h1><span class="brand-color">Welcome</span> to the <strong>Benefit Store</strong>
          </h1>
          <div class="card-block">
            <p>Please Enter Your Code and Email Address</p>
            <form class="banner_form" action="index.html" method="post">
                <div class="form-group mb-3">
                  <input type="text" class="custom-input" v-model="code" value="" placeholder="Enter Code" >
                  <div class="error_message" v-if="codeError">{{formErrors['code_message']}}</div>
                </div>
                <div class="form-group mb-3">
                  <input type="text" class="custom-input" v-model="email" value="" placeholder="Enter Email Address">
                  <div class="error_message" v-if="emailError">{{formErrors['email_message']}}</div>
                </div>
                <div class="button_box mb-3">
                  <button type="button" class="univ-btn" name="button" @click="continueEnrollment">Continue</button>
                </div>
            </form>
          </div>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
          <div class="bn_image">
            <transition-group name="slide-fade">
              <img src="../images/bn_img_one.svg" class="img-fluid" alt="Corenroll" v-bind:key="slideIndex+20">
            </transition-group>
          </div>
        </div>
      </div>
      <!-- <div class="caption">
        <h1><br>Please Enter Your Code and Email</h1>
        <form class="banner_form" action="index.html" method="post">
          <div class="blue_box">
            <div class="input_box zip">
              <input type="text" class="input zip" v-model="code" value="" placeholder="Enter Code" >
              <div class="error_message" v-if="codeError">{{formErrors['code_message']}}</div>
            </div>
            <div class="input_box zip">
              <input type="text" class="input zip" v-model="email" value="" placeholder="Enter Email">
              <div class="error_message" v-if="emailError">{{formErrors['email_message']}}</div>
            </div>
            <div class="button_box">
              <button type="button" name="button" @click="continueEnrollment">Continue</button>
            </div>

          </div>
        </form>
      </div>
      <div class="bn_image">
        <transition-group name="slide-fade">
          <img src="../images/bn_img_one.svg" alt="Corenroll" v-bind:key="slideIndex+20">
        </transition-group>
      </div> -->
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Api from "@/includes/Api";

export default {
  name: "Banner",
  data: function () {
    return {
      codeError: false,
      emailError: false,
      formErrors: [],
      code: null,
      email: null,
      enrollmentId: null,
      savedTab: null
    }
  },
  mounted() {
    let app = this
    app.setContinueEnrollmentFlag()
  },
  methods: {
    setContinueEnrollmentFlag () {
      let app = this
      app.$store.dispatch('continueEnrollmentInfo', true)
    },
    continueEnrollment: function () {
      let app = this;
      let params = {
        code: app.code,
        email: app.email
      }
      app.codeError = false;
      app.emailError = false;
      axios.post(process.env.VUE_APP_API_BASE+'/continue-enrollment', params)
          .then(function (response) {
            if (response.data.status == 'success') {
              window.localStorage.setItem('tempId', response.data.data.result.enrollment_id);
              window.localStorage.setItem('group_id', response.data.data.result.others.group_id);
              window.localStorage.setItem('portal_id', response.data.data.result.others.platform_group_id);
              window.localStorage.setItem('agent_id', response.data.data.result.others.agent_id);
              window.localStorage.setItem('AGENT_ID', response.data.data.result.others.agent_id);
              window.localStorage.setItem('open_type', response.data.data.result.others.open_type);
              app.enrollmentId = response.data.data.result.enrollment_id;
              app.savedTab = response.data.data.result.saved_tab
              if (response.data.data.result.others.type == 'new-user') {
                window.localStorage.setItem('enroll_type', 'new-member');
              } else {
                window.localStorage.setItem('enroll_type', 'existing-member');
              }
              window.localStorage.setItem('continue_track', 'yes');
              app.getNavTabs();
              app.$store.dispatch('continueEnrollmentInfo', false)
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                if (item.target_element == 'code') {
                  app.codeError = true;
                }
                if (item.target_element == 'email') {
                  app.emailError = true;
                }
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
            }
          });
    },
    openTab: function (tempId, tab) {
     this.$root.$emit('cartData', tempId);
      if (tab == 'primary_info') {
        this.$router.push('/enrollment/personal-info');
      } else if (tab == 'contact_info') {
        this.$router.push('/enrollment/contact-info');
      } else if (tab == 'employer_info') {
        this.$router.push('/enrollment/employer-info');
      } else if (tab == 'benificiary_info') {
        this.$router.push('/enrollment/beneficiary-info');
      } else if (tab == 'health_info') {
        this.$router.push('/enrollment/health-questions');
      } else if (tab == 'dependent_info') {
        this.$router.push('/enrollment/dependent-information');
      } else if (tab == 'review_info') {
        this.$router.push('/enrollment/review-confirm');
      } else if (tab == 'aggrement_info') {
        this.$router.push('/enrollment/agreement-signature');
      } else if (tab == 'payment_info') {
        this.$router.push('/enrollment/billing-information');
      } else if (tab == 'cart_info') {
        window.localStorage.setItem('show-quotation', 'no');
        window.localStorage.setItem('personalCodeRedirection',  'Yes');
        this.$router.push('/cart');
      }
      window.location.reload();
    },
    getNavTabs: function() {
      let nav_array = [];
      let tempId = window.localStorage.getItem('tempId');
      //Lets pull Nav Data from API
      Api.get('/get-dynamic-tabs?enrollment_id='+ tempId).then((response) => {
        console.log(response.data.data.result);
        let index = 1;
        let order = 0;
        for (order = 0; order <= 10; order++) {
          response.data.data.result.forEach((nav) => {
            if(nav.order == order){
              if(nav.tab_name == 'primary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Personal Information', "name": 'personal-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'contact_info' && nav.required){
                nav_array.push( {"index": index, "title": 'Contact Information', "name": 'contact-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'employer_info' && nav.required){
                nav_array.push({"index": index, "title": 'Employer Information', "name": 'employer-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'beneficiary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Beneficiary Information', "name": 'beneficiary-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'primary_health_info' && nav.required){
                nav_array.push({"index": index, "title": 'Health Questions', "name": 'health-questions', "visible": true});
                index++;
              }
              if(nav.tab_name == 'dependent_info' && nav.required){
                nav_array.push({"index": index, "title": 'Dependent Information', "name": 'dependent-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'review_info' && nav.required){
                nav_array.push({"index": index, "title": 'Review & Confirmation', "name": 'review-confirm', "visible": true});
                index++;
              }
              if(nav.tab_name == 'agreement_info' && nav.required){
                nav_array.push({"index": index, "title": 'Agreement & Signature', "name": 'agreement-signature', "visible": true});
                index++;
              }
              if(nav.tab_name == 'billing_info' && nav.required){
                nav_array.push({"index": index, "title": 'Billing Information', "name": 'billing-information', "visible": true});
                index++;
              }
            }
          });
        }
        window.localStorage.setItem("nav_array", JSON.stringify(nav_array));
        this.openTab(this.enrollmentId, this.savedTab);
      }).catch(() => {

      }).then(() => {

      });

    }
  }
}
</script>
